<template>
  <PageWithLayout :isBodyBg="false">
    <div class="section_comm">
      <div class="head_section">
        <div class="area_left">
          <strong class="tit_section">출시알림</strong>
        </div>
        <div class="area_right">
          <span class="date_reset">{{ viewModel.responseAt | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          <Button
            btnSize="small"
            btnStyle="secondary_border"
            text="새로고침"
            @onClickBtn="viewModel.init()">
            <IconSvg iconName="reset" :size="16" iconColor="#222" iconDarkColor="#fff"/>
          </Button>
        </div>
      </div>
      <!-- summary -->
      <div class="total_event">
        <GroupBoxWidget>
          <BoxWidget
            iconName="email"
            iconBgColor="#86BEFF"
            title="email"
            :value="viewModel.summaryModel.emailCount | convertNumberToComma"/>
          <BoxWidget
            iconName="phone"
            iconBgColor="#6CDFC3"
            title="phone"
            :value="viewModel.summaryModel.phoneCount | convertNumberToComma"/>
          <BoxWidget
            title="total"
            boxBgColor="#FFD82A"
            :value="( viewModel.summaryModel.totalCount ) | convertNumberToComma"/>
        </GroupBoxWidget>
      </div>
      <!-- weekly -->
      <div class="chart_event">
        <div class="head_chart">
          <button
            class="btn_week btn_prev"
            :disabled="viewModel.weeklyModel.isDisaablePrev"
            @click="viewModel.onClickPrevDate()">
            <IconSvg iconName="arrow_left" :size="12" iconColor="#555" iconDarkColor="#fff"/>
          </button>
          <strong class="date_chart">{{ viewModel.weeklyModel.searchDateRangeWeekly.start_date }} ~ {{ viewModel.weeklyModel.searchDateRangeWeekly.end_date }}</strong>
          <button
            class="btn_week btn_next"
            :disabled="viewModel.weeklyModel.isDisabledNextBtn"
            @click="viewModel.onClickNextDate()">
            <IconSvg iconName="arrow_right" :size="12" iconColor="#555" iconDarkColor="#fff"/>
          </button>
        </div>
        <BarChart
          class="body_chart"
          :chartData="viewModel.weeklyModel.chartData"
          :isStacked="true"/>
      </div>
      <!-- monthly -->
      <Calendar
        ref="calendar"
        :calendarData="viewModel.monthlyModel.calendarData"
        :dataList="viewModel.monthlyModel.monthDataList"
        :scheduleList="viewModel.scheduleModel.scheduleDataList"
        @getData="viewModel.getDataCalendar()"
        @onAddSchedule="date => viewModel.onAddSchedule(date)"
        @onClickDeleteSchedule="scheduleData => viewModel.onClickDeleteSchedule(scheduleData)"
        @onClickModifySchedule="scheduleData => viewModel.onClickModifySchedule(scheduleData)">
        <template v-slot:infoNotes>
          <span class="item_notes">
            <IconSvg iconName="email" :size="13" iconColor ="#fff" bgcolor="#86BEFF"/>email
          </span>
          <span class="item_notes">
            <IconSvg iconName="phone" :size="13" iconColor ="#fff" bgcolor="#6CDFC3"/>phone
          </span>
        </template>
        <template v-slot:calendarDayBody="slotProps">
          <template v-if="slotProps.dayItemData.data">
            <dl
              :class="[{ disabled : !slotProps.dayItemData.data.emailCount || slotProps.dayItemData.data.emailCount === 0 }]">
              <dt><IconSvg iconName="email" :size="13" iconColor ="#fff" bgcolor="#86BEFF"/></dt>
              <dd>{{ slotProps.dayItemData.data.emailCount }}</dd>
            </dl>
            <dl
              :class="[{ disabled : !slotProps.dayItemData.data.phoneCount || slotProps.dayItemData.data.phoneCount === 0 }]">
              <dt><IconSvg iconName="phone" :size="13" iconColor ="#fff" bgcolor="#6CDFC3"/></dt>
              <dd>{{ slotProps.dayItemData.data.phoneCount }}</dd>
            </dl>
            <dl
              :class="['item_total', { disabled : ( !slotProps.dayItemData.data.phoneCount && !slotProps.dayItemData.data.emailCount ) || slotProps.dayItemData.data.phoneCount + slotProps.dayItemData.data.emailCount === 0 }]">
              <dt>total</dt>
              <dd>{{ slotProps.dayItemData.data.phoneCount + slotProps.dayItemData.data.emailCount || 0 }}</dd>
            </dl>
          </template>
        </template>
      </Calendar>
      <!-- daily -->
      <div class="daily_event">
        <Board
          :boardData="viewModel.boardData"
          :searchParams.sync="viewModel.listModel.searchParams"
          :dataList="viewModel.listModel.dailyDataList"
          :paginationData="viewModel.listModel.paginationData"
          @onSearch="viewModel.onSearch()">
        </Board>
      </div>
    </div>
    <template v-slot:popup>
      <SchedulePopup
        v-if="viewModel.isSchedulePopup"
        :isSchedulePopupModify="viewModel.isSchedulePopupModify"
        :scheduleData="viewModel.scheduleItemModel"
        @onClickClose="viewModel.onClickCloseSchedulePopup()"
        @onClickComplete="viewModel.onClickCompleteSchedulePopup()"
        @onClickDelete="scheduleData => viewModel.onClickDeleteSchedule(scheduleData)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import GroupBoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/GroupBoxWidget';
import BoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/BoxWidget';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import BarChart from "@lemontree-ai/lemontree-admin-common-front/components/common/chart/BarChart"
import Calendar from '@lemontree-ai/lemontree-admin-common-front/components/common/calendar/Calendar';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import SchedulePopup from '@lemontree-ai/lemontree-admin-common-front/components/common/calendar/popup/SchedulePopup';

// viewModel
import EventStatisticViewModel from '@/views/landing/statistic/viewModel/EventStatisticViewModel'


export default {
  name:'Statistic',
  components:{
    PageWithLayout,
    GroupBoxWidget,
    BoxWidget,
    Button,
    IconSvg,
    BarChart,
    Calendar,
    Board,
    SchedulePopup
  },
  beforeMount(){
    this.viewModel.init();
  },
  mounted(){
    this.viewModel.monthlyModel.calendarElement = this.$refs.calendar;
  },
  data(){
    return{
      viewModel: new EventStatisticViewModel(),
    }
  },
}
</script>

<style scoped>
.section_comm .date_reset{display:inline-block;margin:18px 10px 6px 0;font-size:12px;line-height:14px;vertical-align:top;letter-spacing:-.02em}
.section_comm .icon_reset{margin:4px 6px 4px -2px}
.section_comm .total_event{margin-top:10px}

/* chart & calendar */
/* .btn_week{padding:8px;border-radius:8px;border:1px solid #e1e1e1;line-height:12px;}
.btn_week.btn_prev{margin-right:20px}
.btn_week.btn_next{margin-left:20px}
.btn_week:disabled{background-color:#eee}
.btn_week:disabled .icon_arrow_left::v-deep g,
.btn_week:disabled .icon_arrow_right::v-deep g{fill:#ccc}
.date_chart{display:inline-block;padding:7px 0 5px;font-weight:700;font-size:16px;line-height:18px;vertical-align:top}
.chart_event,
.calendar_comm,
.daily_event{margin-top:60px}
.head_chart,
.head_calendar{text-align:center}
.body_chart{margin-top:20px}
.body_calendar{margin-top:28px} */
.btn_week{padding:8px;border-radius:8px;border:1px solid #e1e1e1;line-height:12px;}
.btn_week.btn_prev{margin-right:20px}
.btn_week.btn_next{margin-left:20px}
.btn_week:disabled{background-color:#eee}
.btn_week:disabled .icon_arrow_left::v-deep g,
.btn_week:disabled .icon_arrow_right::v-deep g{fill:#ccc}
.date_chart{display:inline-block;padding:7px 0 5px;font-weight:700;font-size:16px;line-height:18px;vertical-align:top}
.chart_event,
.calendar_comm,
.daily_event{margin-top:60px}
.head_chart{text-align:center}
.body_chart{margin-top:20px}

/* calendar */
.info_notes{text-align:center}
.info_notes .item_notes{display:inline-block;font-size:14px;line-height:18px;vertical-align:top}
.info_notes .item_notes .icon_firfin{margin-right:4px;padding:3px 3px 2px;border-radius:5px}
.info_notes .item_notes + .item_notes{margin-left:14px}

.item_day dl{display:block;border-radius:4px;font-weight:700;font-size:13px;line-height:16px;letter-spacing:-.02em}
.item_day dl:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.item_day dl dt{float:left}
.item_day dl dt .icon_firfin{padding:3px 3px 2px;border-radius:5px}
.item_day dl dd{overflow:hidden;padding:2px 0 2px 8px;text-align:left}
.item_day dl.disabled{opacity:0.3;}

.item_day dl.item_total{padding:4px 8px 3px 6px;background-color:#ffeb94;color:#555}
.item_day dl.item_total dd{padding:0 0 0 4px;text-align:right}

.item_day dl + dl{margin-top:8px}

@media (prefers-color-scheme: dark) {
  .btn_week:disabled{border-color:#222;background-color:#222}
  .btn_week:disabled .icon_arrow_left::v-deep g,
  .btn_week:disabled .icon_arrow_right::v-deep g{fill:#555}
}
</style>